<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>

        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-monitor-dashboard</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pa-1">
                <span :style="headerPage">&nbsp;{{ $t("admin.managefeature") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- content -->
        <v-card class="elevation-0" :color="color.BG" id="thiscontainerfeatureadmin">
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <!-- <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate>กำลังอัปเดต</v-progress-circular>
                  </div> -->
                  <v-skeleton-loader
                    class="mx-auto"
                    type="table"
                ></v-skeleton-loader>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                     <div class="text-right">
                      <v-btn
                        class="mr-5 mb-2"
                        x-small
                        fab
                        :dark="color.darkTheme"
                        :color="color.theme"
                        @click="fn_reload(),showReload = false"
                        @mouseover="showReload = true"
                        @mouseleave="showReload = false"
                        ><v-icon small>mdi-reload</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:27px;" 
                          :value="showReload" 
                          :content="$t('balloon.reload')" 
                          left transition="slide-y-transition"
                        ></v-badge>
                        </v-btn>
                    </div>
                  <!-- <v-layout row wrap class="pl-3 pr-3" > -->
                  <!-- <v-flex lg2 :color="color.theme" ><v-select                 
                        v-model="selectset"
                        :items="functionset"
                        item-value="value"
                        item-text="name"
                        :label="$t('admin.selectoption')"
                        outlined                                    
                        dense
                        :color="color.theme"
                        :item-color="color.theme"
                      ></v-select></v-flex> -->
                  <!-- <v-flex lg2 :color="color.theme" v-if="selectset === 'employee'">
                    <v-select                       
                        v-model="filterdepartment"
                        :items="departmentforFilter"                    
                        item-value="department_id"
                        item-text="department_name"
                        :label="$t('admin.filterdepartment')"
                        outlined                
                        required
                        dense
                        :color="color.theme"
                        :item-color="color.theme"
                        @change="loaddate()"
                        ></v-select></v-flex> -->
                  <!-- <v-flex lg2 >
                      <v-text-field
                      v-if="resolutionScreen > 500"
                        class="pt-6"
                        :label="$t('admin.search')"
                        v-model="search"
                        clearable
                        outlined
                        dense
                        :color="color.theme"
                      ></v-text-field>
                     </v-flex>
                     </v-layout> -->
                  <v-tabs v-model="tab" :color="color.theme">
                    <v-tabs-slider :slider-color="color.theme" ></v-tabs-slider>
                    <v-tab :color="color.theme" @click="loaddata()">
                       {{ $t("admin.business") }}                  
                    </v-tab>
                    <v-tab :color="color.theme" @click="loaddata('d')">
                     {{ $t("admin.department") }} 
                    </v-tab>
                    <v-tab :color="color.theme" @click="loaddata('s')">
                     {{ $t("admin.employee") }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <!-- บริษัท -->
                    <v-tab-item>
                      <div v-if="resolutionScreen < 500">
                        <v-list two-line class="mb-6 pb-6 elevation-0" v-if="business.length !== 0">
                          <v-list-item
                            v-for="item in business"
                            :key="item.title"
                            @dblclick="(openmanagefeature = true), (datafeature = item),(selectset = 'All')"
                            style="cursor: pointer;"
                          >
                            <v-list-item-content>
                              <v-list-item-title v-if="$t('default') === 'th'">{{ item.business_name_th }}</v-list-item-title>
                              <v-list-item-title v-else>{{ item.business_name_eng }}</v-list-item-title>
                              <!-- <v-list-item-subtitle>{{$t('admin.storage')}}&nbsp;{{item.storage_account}}</v-list-item-subtitle> -->
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn @click="(openmanagefeature = true), (datafeature = item) ,(selectset = 'All')" class="elevation-0" fab small
                                ><v-icon>settings</v-icon></v-btn
                              >
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <v-list v-else class="mb-6 pb-6 elevation-0">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                      <div v-else>
                        <v-data-table
                          :headers="headerbusiness"
                          :items="business"
                          :search="search"
                          class="elevation-0"
                          :no-data-text="$t('tablefile.empty')"
                          :page.sync="pageD"
                          :items-per-page="sizeD"
                          :hide-default-footer="true"
                          @page-count="pageCount = $event"
                        >
                        <template  v-slot:[`header.type`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template  v-slot:[`header.business`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:[`header.status`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:item="props">
                            <tr style="cursor: pointer" @dblclick="(openmanagefeature = true), (datafeature = props.item),(selectset = 'All')">
                              <td width="7%" class="text-center"><v-icon>location_city</v-icon></td>
                              <td width="86%" v-if="$t('default') === 'th'">{{ props.item.business_name_th }}</td>
                              <td width="86%" v-else>{{ props.item.business_name_eng }}</td>
                              <!-- <td width="40%" class="text-center">{{props.item.department_name}}</td> -->
                              <td width="7%" class="text-center">
                                <v-btn
                                  @click="(openmanagefeature = true), (datafeature = props.item),(selectset = 'All')"
                                  class="elevation-0"
                                  fab
                                  small
                                  ><v-icon>settings</v-icon></v-btn
                                >
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                    </v-tab-item>
                    <!-- แผนก -->
                    <v-tab-item>
                      <v-flex lg2 v-if="resolutionScreen >= 500">
                        <v-text-field
                          class="pt-6"
                          :label="$t('admin.search')"
                          v-model="searchD"
                          clearable
                          outlined
                          dense
                          :color="color.theme"
                        ></v-text-field>
                      </v-flex>
                      <div v-if="resolutionScreen < 500">
                        <v-list two-line class="mb-6 pb-6 elevation-0" v-if="departments.length !== 0">
                          <v-list-item
                            v-for="item in departments"
                            :key="item.title"
                            @dblclick="(openmanagefeature = true), (datafeature = item) ,(selectset = 'department')"
                            style="cursor: pointer;"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{ item.department_name }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn @click="(openmanagefeature = true), (datafeature = item) ,(selectset = 'department')" class="elevation-0" fab small
                                ><v-icon>settings</v-icon></v-btn
                              >
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <v-list v-else class="mb-6 pb-6 elevation-0">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                      <div v-else>
                        <v-data-table
                          :headers="headerdepartment"
                          :items="filteredDepartment"
                          :search="searchD"
                          class="elevation-0"
                          :no-data-text="$t('tablefile.empty')"
                          :page.sync="pageD"
                          :items-per-page="sizeD"
                          :hide-default-footer="true"
                          @page-count="pageCount = $event"
                        >
                        <template v-slot:[`header.type`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:[`header.department_name`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:[`header.status`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:item="props">
                            <tr style="cursor: pointer" @dblclick="(openmanagefeature = true), (datafeature = props.item),(selectset = 'department')">
                              <td width="7%" class="text-center"><v-icon>mdi-domain</v-icon></td>
                              <td width="86%">{{ props.item.department_name }}</td>
                              <td width="7%" class="text-center">
                                <v-btn
                                  @click="(openmanagefeature = true), (datafeature = props.item),(selectset = 'department')"
                                  class="elevation-0"
                                  fab
                                  small
                                  ><v-icon>settings</v-icon></v-btn
                                >
                              </td>
                              <!-- <td width="10%" class="text-center"><v-btn @click="openmanagefeature = true , datafeature=props.item"></v-btn></td> -->
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                      <br />
                        <v-layout row wrap justify-center>
                          <v-flex xs11 lg11 v-if="total_department > 0">
                            <v-pagination
                              :total-visible="10"
                              v-model="pageD"
                              :length="pageCountDepartment || 0"
                              :color="color.theme"
                              @input="changepage('d')"
                            ></v-pagination>
                          </v-flex>
                        </v-layout>
                    </v-tab-item>
                    <!-- พนักงาน  -->
                    <v-tab-item>
                      <v-flex lg2 v-if="resolutionScreen >= 500">
                        <v-text-field
                          class="pt-6"
                          :label="$t('admin.search')"
                          v-model="searchS"
                          clearable
                          outlined
                          dense
                          :color="color.theme"
                          @click:clear="clearSearch"
                        ></v-text-field>
                      </v-flex>
                      <div v-if="resolutionScreen < 500">
                        <v-list two-line class="mb-6 pb-6 elevation-0" v-if="staff.length !== 0">
                          <v-list-item
                            v-for="item in staff"
                            :key="item.title"
                            @dblclick="(openmanagefeature = true), (datafeature = item),(selectset = 'employee')"
                            style="cursor: pointer;"
                          >
                            <v-list-item-content>
                              <v-list-item-title v-if="$t('default') === 'th'">{{ item.staff_name_th }}</v-list-item-title>
                              <v-list-item-title v-else>{{ item.staff_name_en }}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.department_name }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn @click="(openmanagefeature = true), (datafeature = item),(selectset = 'employee')" class="elevation-0" fab small
                                ><v-icon>settings</v-icon></v-btn
                              >
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <v-list v-else class="mb-6 pb-6 elevation-0">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                      <div v-else>
                        <v-data-table
                          :headers="$t('default') === 'en' ? header : header1"
                          :items="filteredStaff"
                          :search="searchS"
                          class="elevation-0"
                          :no-data-text="$t('tablefile.empty')"
                          :page.sync="pageS"
                          :items-per-page="sizeS"
                          :hide-default-footer="true"
                          @page-count="pageCount = $event"
                        >
                        <template  v-slot:[`header.type`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-if="$t('default') === 'th'" v-slot:[`header.staff_name_th`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-else v-slot:[`header.staff_name_en`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:[`header.department_name`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:[`header.status`]="{ header }"
                            ><span :style="headerTable">{{ $t(header.text) }}</span></template
                          >
                          <template v-slot:item="props">
                            <tr style="cursor: pointer" @dblclick="(openmanagefeature = true), (datafeature = props.item),(selectset = 'employee')">
                              <td width="7%" class="text-center"><v-icon>mdi-account</v-icon></td>
                              <td width="50%" v-if="$t('default') === 'th'">
                                {{ props.item.staff_name_th }}
                              </td>
                              <td width="50%" v-else>
                                {{ props.item.staff_name_en }}
                              </td>
                              <td width="36%">{{ props.item.department_name }}</td>
                              <td width="7%" class="text-center">
                                <v-btn
                                  @click="(openmanagefeature = true), (datafeature = props.item),(selectset = 'employee')"
                                  class="elevation-0"
                                  fab
                                  small
                                  ><v-icon>settings</v-icon></v-btn
                                >
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                       <br />
                        <v-layout row wrap justify-center>
                          <v-flex xs11 lg11 v-if="total_account > 0">
                            <v-pagination
                              :total-visible="10"
                              v-model="pageS"
                              :length="pageCount || 0"
                              :color="color.theme"
                              @input="changepage('s')"
                            ></v-pagination>
                          </v-flex>
                        </v-layout>
                    </v-tab-item>
                  </v-tabs-items> </v-flex></v-layout></v-container></v-card-text
        ></v-card>
        <managefeature
          :show="openmanagefeature"
          :datafeature="datafeature"
          :functionsetting="selectset"
          @closemanagefeature="openmanagefeature = false"
          @loaddepartment="(loaddataprogress = true), loaddata()"
        ></managefeature>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
const adminrightclick = () => import("../components/contextmenu/adminrightclick");
const managefeature = () => import("../components/optional/dialog-managefeature");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    onebox_admin_toolbar,
    managefeature,
  },
  data: function() {
    return {
      showReload: false,
      total_account:0,
      total_department:0,
      searchD: "",
      searchS: "",
      tab: null,
      datafeature: {},
      openmanagefeature: false,
      showadminMenu: false,
      functionset: [
        { name: "admin.department", value: "department" },
        { name: "admin.employee", value: "employee" },
        { name: "admin.all", value: "All" },
      ],
      business: [],
      staff: [],
      departments: [],
      departmentforFilter: [],
      filterdepartment: "",
      search: "",
      selectset: "employee",
      loaddataprogress: true,
      sizeS: 20,
      pageS: 1,
      sizeD: 20,
      pageD: 1,
      sizeB: 1,
      pageB: 1,
      listsize: [20, 50, 100],
      header: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "admin.name",
          align: "",
          value: "staff_name_en",
          width: "50%",
          sortable: false,
        },
        {
          text: "admin.department",
          align: "",
          value: "department_name",
          width: "36%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
      header1: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "admin.name",
          align: "",
          value: "staff_name_th",
          width: "50%",
          sortable: false,
        },
        {
          text: "admin.department",
          align: "",
          value: "department_name",
          width: "36%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
      headerdepartment: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "admin.department",
          align: "",
          value: "department_name",
          width: "86%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
      headerbusiness: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "admin.business",
          align: "",
          value: "business",
          width: "86%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    setname() {
      if (this.$t("default") === "th") {
        return "staff_name_th";
      } else {
        return "staff_name_en";
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.total_account;
        let s = this.sizeS;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    pageCountDepartment: {
      get() {
        let l = this.total_department;
        let s = this.sizeD;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      //console.log("ffff");     
      const start = (this.pageS - 1) * this.sizeS;
      //console.log("ffff",start);  
      const end = start + this.sizeS;
      //console.log("ffff",this.staff.slice(start, start + this.sizeS));  
      return this.staff.slice(start, start + this.sizeS);
      //console.log("เข้าา",this.staff.slice(start, start + this.sizeS));
    },
    paginatedDepartment() {
      const start = (this.pageD - 1) * this.sizeD;
      const end = start + this.sizeD;
      return this.departments.slice(start, start + this.sizeD);
    },
    filteredStaff() {
      console.log("testest");
      let staff;
      staff = this.staff.filter((item) => {
        if (!this.searchS) return this.staff;
        return (
          item.staff_name_en.toLowerCase().includes(this.searchS.toLowerCase()) ||
          item.staff_name_th.toLowerCase().includes(this.searchS.toLowerCase()) ||
          item.department_name.toLowerCase().includes(this.searchS.toLowerCase())
        );
      });

      console.log(staff);
      return staff;
    },
    filteredDepartment() {
      console.log("testest");
      let depart;
      depart = this.departments.filter((item) => {
        if (!this.searchD) return this.departments;
        return (
          item.department_name.toLowerCase().includes(this.searchD.toLowerCase())
        );
      });

      console.log(depart);
      return depart;
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    changepage(status) {
     this.loaddata(status);
    },
    clearSearch() {
      this.search = "";
    },
   async loaddata(status) {
      console.log("sssssss",status);
      let cal_limit ;
      let cal_offset ;
      if(status === 'd'){
      cal_limit = this.sizeD;
      cal_offset = this.sizeD * (this.pageD - 1);
      }else{
      cal_limit = this.sizeS;
      cal_offset = this.sizeS* (this.pageS - 1);
      }
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_permission_feature",
        limit: cal_limit,
        offset: cal_offset,
        search: ""
        
      };
      //console.log(payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT + "/api/v2/select_account_in_business",payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          //console.log("get",response);
          if (response.data.status === "OK") {
            this.business = [];
            this.departments = [];
            this.staff = [];
            let databuffer = {};
            console.log("get", response.data);
            this.total_account = response.data.total_account;
            this.total_department = response.data.total_dept;
            for (let i = 0; i < response.data.account.length; i++) {
              let bufferstaff = {};
              bufferstaff["staff_id"] = response.data.account[i]["id"];
              bufferstaff["staff_name_en"] = response.data.account[i]["name_eng"];
              bufferstaff["staff_name_th"] = response.data.account[i]["name_th"];
              bufferstaff["department_name"] = response.data.account[i]["department"];
              bufferstaff["staff_feature"] = response.data.account[i]["permission_feature"];
              this.staff.push(bufferstaff);
            }
             console.log( this.staff);
            for (let i = 0; i < response.data.department.length; i++) {
              let bufferdepartment = {};
              bufferdepartment["department_name"] = response.data.department[i]["department_name"];
              bufferdepartment["department_id"] = response.data.department[i]["id"];
              bufferdepartment["department_feature"] = response.data.department[i]["permission_feature"];
              this.departments.push(bufferdepartment);
            }

            let bufferbusiness = {};
            bufferbusiness["business_name_eng"] = response.data.business["name_eng"];
            bufferbusiness["business_name_th"] = response.data.business["name_th"];
            bufferbusiness["business_id"] = response.data.business["id"];
            bufferbusiness["business_feature"] = response.data.business["permission_feature"];
            bufferbusiness["package_id"] = response.data.business["package_id"];
            bufferbusiness["package_name"] = response.data.business["package_name"];
            this.business.push(bufferbusiness);

            this.loaddataprogress = false;
            //}
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async fn_reload(status) {
    console.log("sssssss",status);
     this.loaddataprogress = true;
    let cal_limit ;
    let cal_offset ;
    if(status === 'd'){
    cal_limit = this.sizeD;
    cal_offset = this.sizeD * (this.pageD - 1);
    }else{
    cal_limit = this.sizeS;
    cal_offset = this.sizeS* (this.pageS - 1);
    }
    var payload = {
      business_id: this.dataAccountActive.business_info.business_id,
      account_id: this.dataAccountId,
      accesstoken: this.dataAccesstoken,
      api_type: "setting_permission_feature",
      limit: cal_limit,
      offset: cal_offset,
      
    };
    //console.log(payload);
    let auth = await gbfGenerate.generateToken();
    await this.axios
      .post(process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT + "/api/update_account_in_business",payload,{
          headers: { Authorization: auth.code },})
      .then((response) => {
        //console.log("get",response);
        if (response.data.status === "OK") {
          this.business = [];
          this.departments = [];
          this.staff = [];
          let databuffer = {};
          console.log("get", response.data);
          this.total_account = response.data.total_account;
          this.total_department = response.data.total_dept;
          for (let i = 0; i < response.data.account.length; i++) {
            let bufferstaff = {};
            bufferstaff["staff_id"] = response.data.account[i]["id"];
            bufferstaff["staff_name_en"] = response.data.account[i]["name_eng"];
            bufferstaff["staff_name_th"] = response.data.account[i]["name_th"];
            bufferstaff["department_name"] = response.data.account[i]["department"];
            bufferstaff["staff_feature"] = response.data.account[i]["permission_feature"];
            this.staff.push(bufferstaff);
          }
            console.log( this.staff);
          for (let i = 0; i < response.data.department.length; i++) {
            let bufferdepartment = {};
            bufferdepartment["department_name"] = response.data.department[i]["department_name"];
            bufferdepartment["department_id"] = response.data.department[i]["id"];
            bufferdepartment["department_feature"] = response.data.department[i]["permission_feature"];
            this.departments.push(bufferdepartment);
          }

          let bufferbusiness = {};
          bufferbusiness["business_name_eng"] = response.data.business["name_eng"];
          bufferbusiness["business_name_th"] = response.data.business["name_th"];
          bufferbusiness["business_id"] = response.data.business["id"];
          bufferbusiness["business_feature"] = response.data.business["permission_feature"];
          bufferbusiness["package_id"] = response.data.business["package_id"];
          bufferbusiness["package_name"] = response.data.business["package_name"];
          this.business.push(bufferbusiness);

          this.loaddataprogress = false;
          //}
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        Toast.fire({
          icon: "error",
          title: this.$t("toast.cannotconnectonebox"),
        });
      });
  },

  },
  mounted() {
    this.loaddata();
  },
  created() {},
};
</script>
<style>
#thiscontainerfeatureadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}
</style>
